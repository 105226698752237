// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-countries-template-js": () => import("./../src/templates/countriesTemplate.js" /* webpackChunkName: "component---src-templates-countries-template-js" */),
  "component---src-templates-news-template-js": () => import("./../src/templates/newsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mixtapes-js": () => import("./../src/pages/mixtapes.js" /* webpackChunkName: "component---src-pages-mixtapes-js" */)
}

