module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Vollkorn:bold","Vollkorn:regular"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Haute Culture","short_name":"hauteculture","start_url":"/","background_color":"#19f77d","theme_color":"#19f77d","display":"minimal-ui","icon":"static/assets/images/hcicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-37446515-4","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"cookieDomain":"hauteculture.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
